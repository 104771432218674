import styled from "styled-components";

export const StyledGallerySection = styled.div`
  width: 100%;
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);
  background: #faf7f1;

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

export const StyledRealizationFirstSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(40px, 4.167vw, 80px);
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledLeftWrapper = styled.div`
  width: 60%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledRightWrapper = styled.div`
  width: 40%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledDescWrapper = styled.div`
  font: 300 clamp(15px, 0.92vw, 20px) / 1.33em Poppins;
  margin-bottom: clamp(2px, 2.083vw, 40px);
`;
