/** @format */

import React from "react";
import { graphql } from "gatsby";

import Gallery from "../../molecules/Gallery/Gallery";
import Banner from "../../molecules/Banner/Banner";
import Container from "../../atoms/Container/Container";
import Image from "../../atoms/Image/Image";
import ParseText from "../../atoms/ParseText/ParseText";
import HelmetTemplate from "../../molecules/HelmetTemplate/HelmetTemplate";

import {
  StyledGallerySection,
  StyledRealizationFirstSection,
  StyledLeftWrapper,
  StyledRightWrapper,
  StyledDescWrapper,
} from "./StyledRealization";
import { StyledText } from "../../atoms/StyledText/StyledText";

const Realization = ({
  data: {
    datoCmsRealizacja: {
      zdjCiaZRealizacji,
      duEZdjCieNaStronDedykowan,
      tytuRealizacji,
      powierzchnia,
      opisPoLewo,
      rozmiar,
      lokalizacja,
      zdjCiePoPrawoObokTekstu,
    },
  },
}) => {
  return (
    <main>
      <HelmetTemplate
        title={tytuRealizacji}
        desc={opisPoLewo ? opisPoLewo : ""}
      />
      <Container>
        <Banner image={duEZdjCieNaStronDedykowan} />
        <StyledRealizationFirstSection>
          <StyledLeftWrapper>
            <StyledText
              hasdeclaredfontsize="clamp(22px, 2.292vw, 44px)"
              hasdeclaredfontweight="700"
              hasdeclaredpadding="0 0 clamp(2px, 2.083vw, 40px) 0"
            >
              {tytuRealizacji}
            </StyledText>
            <StyledDescWrapper>
              <ParseText text={opisPoLewo} />
            </StyledDescWrapper>
            <StyledText
              hasdeclaredfontcolor="rgba(11, 199, 109, 1)"
              hasdeclaredfontsize="clamp(16px, 1.038vw, 22px)"
              hasdeclaredlineheight="1.35em"
              hasdeclaredmargin="0 0 20px 0"
            >
              Lokalizacja: <span style={{ color: "#000" }}>{lokalizacja}</span>
            </StyledText>
            <StyledText
              hasdeclaredfontcolor="rgba(11, 199, 109, 1)"
              hasdeclaredfontsize="clamp(16px, 1.038vw, 22px)"
              hasdeclaredlineheight="1.35em"
              hasdeclaredmargin="0 0 20px 0"
            >
              Rozmiar: <span style={{ color: "#000" }}>{rozmiar}</span>
            </StyledText>
            <StyledText
              hasdeclaredfontcolor="rgba(11, 199, 109, 1)"
              hasdeclaredfontsize="clamp(16px, 1.038vw, 22px)"
              hasdeclaredlineheight="1.35em"
            >
              Powierzchnia:{" "}
              <span style={{ color: "#000" }}>{powierzchnia}</span>
            </StyledText>
          </StyledLeftWrapper>
          <StyledRightWrapper>
            <Image image={zdjCiePoPrawoObokTekstu} />
          </StyledRightWrapper>
        </StyledRealizationFirstSection>
        {zdjCiaZRealizacji.length ? (
          <StyledGallerySection>
            <StyledText
              hasdeclaredfontsize="clamp(22px, 2.292vw, 44px)"
              hasdeclaredfontweight="700"
              hasdeclaredpadding="0 0 clamp(2px, 2.083vw, 40px) 0"
            >
              GALERIA:
            </StyledText>
            <Gallery images={zdjCiaZRealizacji} />
          </StyledGallerySection>
        ) : null}
      </Container>
    </main>
  );
};

export default Realization;

export const query = graphql`
  query realizationPageQuery($realizacjaId: String) {
    datoCmsRealizacja(id: { eq: $realizacjaId }) {
      zdjCiaZRealizacji {
        alt
        title
        gatsbyImageData
      }
      duEZdjCieNaStronDedykowan {
        alt
        title
        gatsbyImageData
      }
      zdjCiePoPrawoObokTekstu {
        alt
        title
        gatsbyImageData
      }
      tytuRealizacji
      powierzchnia
      rozmiar
      opisPoLewo
      lokalizacja
    }
  }
`;
